import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, CardTitle, Media, Button, Modal, ModalHeader, ModalBody, Label, } from "reactstrap";

import Breadcrumbs from '../../components/Common/Breadcrumb';
//i18n
import { withNamespaces } from 'react-i18next';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			token: "",
			customers: [],
			products: [],
			customersNaProds: [],
			create_temp: false,
			new_product_id: "",
			customer_now: null,
			nodes: [],
			checked: [],
			expanded: [],
			create_prod: false,
		};
	}

	showToast(title, message, toastType){
		toastr.options = {
			closeButton: true,
			debug: false,
			extendedTimeOut: "1000",
			hideDuration: "1000",
			hideEasing: "linear",
			hideMethod: "fadeOut",
			newestOnTop: false,
			positionClass: "toast-top-center",
			preventDuplicates: true,
			progressBar: true,
			showDuration: "300",
			showEasing: "swing",
			showMethod: "fadeIn",
			timeOut: "5000",
		}
		
		if(toastType === "info")
			toastr.info(message,title)
		else if(toastType === "warning")
			toastr.warning(message,title)
		else if(toastType === "error")
			toastr.error(message,title)
		else
			toastr.success(message,title)
	}

	sortProds() {
		var prods = this.state.products.map((prod) => {
			var temp = {
				label: prod.productName.split(":")[0],
			}
			if (prod.productName.split(":").length > 1){
				temp["children"] = [
					{
						value: prod.productId,
						label: prod.productName.split(":")[1]
					}
				]
			} else {
				temp["value"] = prod.productId
			}
			return temp
		});
		var nodes = [];
		prods.forEach(element => {
			if (element.children){
				var tmp = nodes.filter((elem) => {
					return elem.label === element.label
				});
				if (tmp.length === 0){
					nodes.push(element);
				} else {
					nodes.map((elem) => {
						if (elem.label === element.label){
							elem["children"].push(element.children[0])
						}
					})
				}
			} else {
				nodes.push(element);
			}
		});
		this.setState({
			nodes: nodes
		});
	}

	async getProducts(){
		var query = `query get_prods{
			getProducts(email: "${this.state.email}", token: "${this.state.token}") {
				productId
				productName
			}
		}`;
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ "query": query })
		};
		const resp = await fetch('/api/', requestOptions);
		const data = await resp.json();
		if (data && data.data && data.data.getProducts && data.data.getProducts.length > 0){
			this.setState({
				products: data.data.getProducts,
			}, () => {
				this.sortProds();
			});
		}
	}

	async getCustomers(){
		var query = `query get_prods{
			getCustomers(email: "${this.state.email}", token: "${this.state.token}") {
				customerId
				customerName
			}
		}`;
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ "query": query })
		};
		const resp = await fetch('/api/', requestOptions);
		const data = await resp.json();
		if (data && data.data && data.data.getCustomers && data.data.getCustomers.length > 0){
			this.setState({
				customers: data.data.getCustomers,
			});
		}
	}

	async getNaProds(cust_id) {
		var query = `query get_na_prods{
			getNaProdcuts(email: "${this.state.email}", token: "${this.state.token}", custId: ${cust_id}) {
				productId
			}
		}`;
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ "query": query })
		};
		const resp = await fetch('/api/', requestOptions);
		const data = await resp.json();
		if (data && data.data && data.data.getNaProdcuts){
			this.setState({
				customer_now: cust_id,
				checked: data.data.getNaProdcuts.map((elem) => {return parseInt(elem.productId)}),
				create_temp: true
			});
		}
	}

	get_login_info() {
		var user = JSON.parse(localStorage.getItem("authUser"));
		if (user.hasOwnProperty("email") && user.hasOwnProperty("token") ){
			this.setState({
				email: user.email,
				token: user.token
			}, () => {
				this.getProducts();
				this.getCustomers();
			});
		}
	}

	async add_new_product(){
		var checked = this.state.checked.map((elem) => {
			return parseInt(elem)
		});
		var query = `mutation add_product{
			addProduct(customerId: ${this.state.customer_now}, productId: [${checked}], email: "${this.state.email}", token: "${this.state.token}") {
				result
			}
		}`;
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ "query": query })
		};
		const resp = await fetch('/api/', requestOptions);
		const data = await resp.json();
		if (data && data.data && data.data.addProduct && data.data.addProduct.result === 1){
			this.showToast("Product Added", "Product successfully added to the customer", "success");
		} else {
			this.showToast("Failed to add product", "Failed to add Product to the customer", "error");
		}
		this.setState({
			create_temp: false,
		})
	}

	async create_new_product(){
		var query = `mutation add_product{
			createProduct(email: "${this.state.email}", token: "${this.state.token}", productName: "${this.state.new_product_id}") {
				result
			}
		}`;
		console.log(query);
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ "query": query })
		};
		const resp = await fetch('/api/', requestOptions);
		const data = await resp.json();
		if (data && data.data && data.data.createProduct && data.data.createProduct.result === 1){
			this.showToast("Product Create", "New Product successfully created", "success");
		} else {
			this.showToast("Failed to create product", "Failed to create new Product", "error");
		}
		this.setState({
			create_prod: false,
		})
	}


	componentDidMount() {
		this.get_login_info();
	}

	render() {
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						<Breadcrumbs title={this.props.t('My Cloud')} breadcrumbItem={this.props.t('Dashboard')} />
						<Row>
							<Col xl="4">
								<Card className="mini-stats-wid">
									<CardTitle className="m-4">Products Available</CardTitle>
									<CardBody>
										{
											this.state.products.map((prod, key) =>
												<Row key={"_col_" + key}>
													<Col lg={12} md={12}>
														<Media body>
															<p className="mb-2"><span className="text-muted">{prod.productId}</span>: {prod.productName}</p>
														</Media>
													</Col>
												</Row>
											)
										}
										<Button className="mt-2 btn btn-block" color="warning" onClick={() => {this.setState({create_prod: true})}}>
											Add Product
										</Button>
									</CardBody>
								</Card>
							</Col>
							<Col xl="8">
								<Card className="mini-stats-wid">
									<CardTitle className="m-4">Customers</CardTitle>
									<CardBody>
										{
											this.state.customers.map((cstmr, key) =>
												<Row key={"_col_" + key}>
													<Col lg={8} md={8}>
														<Card>
															<CardBody>
																<Media body>
																	<p className="mb-2"><span className="text-muted">{cstmr.customerId + 1}</span>: {cstmr.customerName}</p>
																</Media>
															</CardBody>
														</Card>
													</Col>
													<Col lg={4} md={4}>
														<Button className="btn btn-success btn-block" color="success" onClick={() => this.getNaProds(cstmr.customerId)}>
															Modify Products
														</Button>
													</Col>
												</Row>
											)
										}
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
					<Modal isOpen={this.state.create_temp} role="dialog" autoFocus={true} centered={true} className="modal-md" tabIndex="-1">
						<div className="modal-content">
							<ModalHeader toggle={() => this.setState({create_temp: !this.state.create_temp})}>
								Add Product for Customer
							</ModalHeader >
							<ModalBody className="d-flex justify-content-center">
								<AvForm>
									<Label>Add Products</Label>
									<CheckboxTree
										nodes={this.state.nodes}
										checked={this.state.checked}
										expanded={this.state.expanded}
										onCheck={checked => this.setState({ checked })}
										onExpand={expanded => this.setState({ expanded })}
										iconsClass="fa5"
									/>
									<Button className="btn btn-block mt-4" color="success" type="submit" onClick={() => this.add_new_product()}>
										Add Product
									</Button>

								</AvForm>
							</ModalBody>
						</div>
					</Modal>
					<Modal isOpen={this.state.create_prod} role="dialog" autoFocus={true} centered={true} className="modal-md" tabIndex="-1">
						<div className="modal-content">
							<ModalHeader toggle={() => this.setState({create_prod: !this.state.create_prod})}>
								Modify Product for Customer
							</ModalHeader >
							<ModalBody className="d-flex justify-content-center">
								<AvForm onValidSubmit={() => {this.create_new_product()}}>
									<AvField
										name="new_product"
										placeholder="Product Name"
										type="text"
										helpMessage="Enter the product with ':' as seperator"
										errorMessage="Enter a proper product name"
										label="Product Name"
										className="form-control"
										required
										onChange = {(event) =>  this.setState({new_product_id: event.target.value}) } 
									/> 
									<Button className="btn btn-block mt-4" color="success">
										Create Product
									</Button>

								</AvForm>
							</ModalBody>
						</div>
					</Modal>
				</div>
			</React.Fragment>
		);
	}
}

export default withNamespaces()(Dashboard);
