import React, { Component } from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";

import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

import "./assets/scss/theme.scss";

import fakeBackend from './helpers/AuthType/fakeBackend';

fakeBackend();

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			is_crct_login: false,
		};
	}
 
	ValidateUserLogin() {
		const dat = localStorage.getItem("authUser");
		if(dat){
			var data = JSON.parse(dat);
			if (data.hasOwnProperty("email") && data.hasOwnProperty("token")){
				var query = `query validate_login{
					validateLogin(email: "${data.email}", token: "${data.token}"){
						result
					}
				}`;
				const requestOptions = {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({ "query": query })
				};
				fetch('/api/', requestOptions).then((resp) => {
					resp.json().then( (response) => {
						if (response && response.data && response.data.validateLogin && response.data.validateLogin.result === "1")
							this.setState({
								is_crct_login: true,
							});
						else{
							localStorage.removeItem("authUser");
							this.setState({
								is_crct_login: false,
							});
							window.location = "/login";
						}
					});
				});
			}
			else{
				localStorage.removeItem("authUser");
				this.setState({
					is_crct_login: false,
				});
				window.location = "/login";
			}
		}
		else
			this.setState({
				is_crct_login: false,
			});
	}

	componentDidMount(){
		this.ValidateUserLogin();
	}

	render() {
		const Layout = HorizontalLayout;
		return (
			<React.Fragment>
				<Router>
					<Switch>
						{publicRoutes.map((route, idx) => (
							<AppRoute
								path={route.path}
								layout={NonAuthLayout}
								component={route.component}
								key={idx}
								isAuthProtected={false}
								isLoggedIn={this.state.is_crct_login}
							/>
						))}

						{authProtectedRoutes.map((route, idx) => (
							<AppRoute
								path={route.path}
								layout={Layout}
								component={route.component}
								key={idx}
								isAuthProtected={true}
								isLoggedIn={this.state.is_crct_login}
							/>
						))}
					</Switch>
				</Router>
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		layout: state.Layout
	};
};

export default connect(mapStateToProps, null)(App);
