import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import accessToken from "../jwt-token-access/accessToken";

	
const fakeBackend = () => {
	// This sets the mock adapter on the default instance
	var mock = new MockAdapter(axios);
	
	mock.onPost('/post-jwt-login').reply(async function (config) {
		const user = JSON.parse(config['data']);
		var query = `query login_student{
			login(email: "${user.email}", password:"${user.password}") {
				result
			}
		}`;
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ "query": query })
		};
		const resp = await fetch('/api/', requestOptions);
		const data = await resp.json();
		return new Promise(function (resolve, reject) {
			setTimeout(function () {
				if (data && data.data && data.data.login && data.data.login.hasOwnProperty("result")) {
					if(data.data.login.result !== "0"){
						delete user["password"];
						const validUserObj = { "email": user.email, "token": data.data.login.result };
						resolve([200, validUserObj]);
					} else {
						reject([400, "Username and password are invalid. Please enter correct username and password"]);
					}
				} else {
					reject([500, "Error With Server. Please try again after some time"]);
				}
			});
		});	
	});
}

export default fakeBackend;